import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import {
  Form,
  Input,
  Radio,
  Switch,
  Button,
  MessagePlugin,
  DatePicker,
  DialogPlugin,
  Table,
  Space,
  Dialog,
  Link,
  Typography,
} from 'tdesign-react';
import { FEATURE_MINIMAL_VERSION, MACHINE_FEATURES } from '../../config';
import cron from 'cron-validate';
import dayjs from 'dayjs';
import {
  CommonCpuTask,
  CommonMemoryTask,
  DiskTracingTask,
  DynamicDetectionTask,
  GolangCpuTask,
  GolangMemoryTask,
  JavaCpuTask,
  JavaHeapDumpTask,
  JavaMemoryCompositeTask,
  JavaMemoryTask,
  TimingAnalysisTask,
} from './tasks';
import '@tencent/tea-component/dist/tea.css';
import axios from 'axios';
import { HOST_URL } from '@/config';
import { goodStatus } from '@/utils/ErrorConfirm';
import { getOAName } from '@/utils/Storage';

const { FormItem } = Form;
const { Text } = Typography;

// 封装visable属性
const VisFormItem = props => (props.visable ? props.children : '');

VisFormItem.propTypes = {
  visable: PropTypes.bool,
};

VisFormItem.defaultProps = {
  visable: false,
};

// 任务类型选择器
// Todo: 如果需要支持按照agent版本功能的话，可以在选择agent后更新taskTypesSelector
const taskTypesSelector = [
  {
    label: '通用分析',
    children: [
      CommonCpuTask,
      CommonMemoryTask,
    ],
  },
  {
    label: 'Golang分析',
    children: [
      GolangCpuTask,
      GolangMemoryTask,
    ],
  },
  {
    label: 'Java分析',
    children: [
      JavaCpuTask,
      JavaMemoryTask,
      JavaHeapDumpTask,
      JavaMemoryCompositeTask,
    ],
  },
  TimingAnalysisTask,
  DiskTracingTask,
  DynamicDetectionTask,
];

const versionToNumber = (version) => {
  let result = 0;
  if (version === undefined || version === '') {
    return result;
  }
  const versionArray = version.replace(/^v/, '').replace(/\n$/, '').split('.');
  for (let index = 0; index < versionArray.length; index++) {
    result = result * 100 + parseInt(versionArray[index], 10);
  }
  Log.debug('version', version, 'version number', result);
  return result;
};

const featureEnable = (version, minimalVersion) => versionToNumber(version) >= minimalVersion;

const StartTaskForm = (props) => {
  const getDefaultAgent = () => {
    const onlineAgents = props.agents.filter(agent => agent.online);
    // 若用户只有一个在线的 agent，则默认选择该 agent
    if (onlineAgents.length === 1) {
      return onlineAgents[0];
    }
    return null;
  };

  const [taskTypes, setTaskTypes] = useState(0); // 子任务列别
  const [currentFormItemIndex, setCurrentFormItemIndex] = useState(0);
  const [currentFormItemSelector, setCurrentFormItemSelector] = useState(taskTypesSelector[0].children[0]);
  const [currentAgent, setCurrentAgent] = useState({
    // version 为已选择的agents中最小的 version，用于 featureEnable 判断
    version: getDefaultAgent()?.version || '',
    unavailableFeatures: (getDefaultAgent()?.unavailable_features && new Set(getDefaultAgent().unavailable_features))
      || new Set(),
  });
  const [curlCommand, setCurlCommand] = useState('');
  const [showCurlCommand, setShowCurlCommand] = useState(false);

  const noUnavailableFeatures = (...features) => {
    if (!currentAgent.unavailableFeatures) {
      return true;
    }
    for (const feature of features) {
      if (currentAgent.unavailableFeatures.has(feature)) {
        return false;
      }
    }
    return true;
  };

  const [form] = Form.useForm();
  const perfWidecommand = Form.useWatch('perf_wide', form);
  const tracingWidecommand = Form.useWatch('tracing_wide', form);

  useEffect(() => {
    // 根据 perf_wide_command 和 tracing_wide_command 的值更新 duration 的初始值
    const initialDuration = perfWidecommand === 'command' || tracingWidecommand === 'command' ?  "" : 10;
    form.setFieldsValue({ duration: initialDuration });
  }, [perfWidecommand, tracingWidecommand, form]);

  const changeTaskGeneralType = (value) => {
    Log.debug('changeTaskGeneralType', taskTypesSelector[value]);
    setTaskTypes(value);
    if (taskTypesSelector[value].children) {
      changeTaskType(0);
    }
  };

  const changeTaskType = (value) => {
    setCurrentFormItemIndex(value);
  };

  useEffect(() => {
    if (localStorage.getItem('key') !== null) {
      return;
    }
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/users`,
      params: {
        user: getOAName(),
      } })
      .then((response) => {
        if (goodStatus(response)) {
          window.localStorage.setItem('key', response?.data?.key);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let selector;
    if (taskTypesSelector[taskTypes].children) {
      selector = taskTypesSelector[taskTypes].children[currentFormItemIndex];
      form.setFieldsValue({ name: `${taskTypesSelector[taskTypes].label}-${selector.label}` });
    } else {
      selector = taskTypesSelector[taskTypes];
      form.setFieldsValue({ name: selector.label });
    }
    setCurrentFormItemSelector(selector);
    Log.debug('currentFormItemSelector', taskTypes, currentFormItemIndex, selector);
  }, [taskTypes, currentFormItemIndex]);

  // 提交数据
  const onSubmit = (e) => {
    const fields = form.getFieldsValue(true);
    Log.debug(e);
    Log.debug('fields', fields);
    if (e.validateResult !== true) {
      MessagePlugin.error('请正确填写表单');
      return;
    }

    const submit = () => {
      props.onClick(Object.assign(fields, {
        task_type: currentFormItemSelector.task_type,
        profiler_type: currentFormItemSelector.profiler_type,
        event: currentFormItemSelector.event,
        target_host: selectedRowKeys,
      }));
    };
    const profilingThreshold = 120 * 1299; // 120s * 1299Hz

    // 当Agent未有资源预检特性时，对超过阈值的任务进行警告
    if (!featureEnable(currentAgent.version, FEATURE_MINIMAL_VERSION.resource_precheck)
      && (parseInt(fields.duration, 10) * parseInt(fields.profiling_freqence, 10) > profilingThreshold)) {
      const confirmDia = DialogPlugin.confirm({
        header: '继续执行任务？',
        body: <>当前任务的执行时长和频率较高，可能对目标机器会造成较长时间的负载。是否继续执行任务? <br/><br/>
          <a
            href="https://iwiki.woa.com/p/4009694046"
            target="_blank" rel="noreferrer">升级Agent</a>至1.3.1及以上版本, 可自动启用资源预估特性，在机器资源不足时自动中断采样任务。
          <br/><br/>
          Agent开销评估：<a
            href="https://iwiki.woa.com/p/4009585311"
            target="_blank" rel="noreferrer">iWiki</a>
        </>,
        confirmBtn: '确认',
        cancelBtn: '取消',
        onConfirm: () => {
          submit();
          confirmDia.hide();
        },
        onClose: () => {
          confirmDia.hide();
        },
      });

      return;
    }

    submit();
  };

  // 生成命令
  const genCurlCommand = () => {
    const fields = form.getFieldsValue(true);
    const command = props.genCurlCommand(Object.assign(fields, {
      task_type: currentFormItemSelector.task_type,
      profiler_type: currentFormItemSelector.profiler_type,
      event: currentFormItemSelector.event,
      target_host: selectedRowKeys,
    }), window.localStorage.getItem('key'));
    console.log(curlCommand);
    setCurlCommand(String(command));
    setShowCurlCommand(true);
  };

  // 重置表单
  const onReset = (e) => {
    console.log(e);
    changeTaskGeneralType(0);
    setCurrentAgent({
      version: '',
    });
    MessagePlugin.info('重置成功');
  };

  const options = [];
  props.agents.map((agent) => {
    if (agent.online) {
      const unavailableFeatures = new Set();
      agent.unavailable_features?.forEach((feature) => {
        // 将 feature 的数字后两位置置为 00(这两位为具体原因，不需要用于判断)
        unavailableFeatures.add(feature - (feature % 100));
      });

      let version = '版本过低';
      if (agent.version) {
        version = `v${agent.version}`;
      }

      options.push({
        label: `${agent.ipaddr} [${agent.hostname}]`,
        title: `${agent.ipaddr} [${agent.hostname}]`,
        value: agent.ipaddr,
        version,
        unavailableFeatures,
        key: agent.ipaddr,
      });
    }
    return 0;
  });

  Log.debug('options', options);

  const columns = [
    { colKey: 'choice', type: 'multiple', width: '35' },
    { colKey: 'label', title: '主机', width: '260' },
    { colKey: 'version', title: '版本号', width: '60' },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState(getDefaultAgent() ? [getDefaultAgent().ipaddr] : []);

  const onSelectChange = (value) => {
    Log.debug('choose value', value);
    setSelectedRowKeys(value);

    let minVersion = '100.0.0';
    if (value.length > 0 && options.length > 0) {
      for (const option of options) {
        if (value.includes(option.value) && option.version
          && versionToNumber(minVersion) > versionToNumber(option.version)) {
          minVersion = option.version;
        }
      }
    }

    // 不可用特性计算为并集
    const unavailableFeatures = new Set();
    value.forEach((a) => {
      options[a]?.unavailableFeatures.forEach((feature) => {
        unavailableFeatures.add(feature);
      });
    });

    setCurrentAgent({
      version: minVersion,
      unavailableFeatures,
    });
  };

  const disableTime = () => ({
    // 返回小时数组
    hour: [...Array(dayjs().hour()).keys()],
    minute: [...Array(dayjs().minute() + 1).keys()],
  });
  const forbiddenStrings = ["route", "ifconfig", "ping", "dhclient", "net",
  "iptables", "rfcomm", "wvdial", "iwconfig", "miitool",
  "rpm", "update", "yum",
  "service", "chkconfig", "systemctl start", "systemctl stop", "systemctl reload", "systemctl restart",
  "systemctl status", "systemctl enable", "systemctl disable", "updatedb",
  "fdisk", "sfdisk", "parted", "partprobe", "mount", "umount",
  "visudo", "chown", "chmod", "chgrp",
  "nice", "kill", "killall", "modprobe", "curl"];

  // 为每个不允许的字符串创建一个正则表达式规则
  const forbiddenPatterns = forbiddenStrings.map(str => ({
      pattern: new RegExp(`^(?:(?!${str}).)*$`), // 使用否定前视断言确保不包含指定字符串
      message: `请勿使用权限指令 "${str}"`,
  }));

  return (
    <>
    <Form
      form={form}
      layout="vertical"
      onSubmit={onSubmit}
      labelWidth={100}
      onReset={onReset}
      scrollToFirstError="smooth"
      resetType='initial'
    >
      <FormItem label="任务名" name="name" initialData={'通用采样分析'}
                rules={[{ required: true, type: 'error', message: '请输入任务名' }]}>
        <Input/>
      </FormItem>
      <FormItem
        label="目标主机"
        name="target_host"
        rules={[
          {
            validator: () => !(form.getFieldsValue(true).task_general_type === 'multi' && selectedRowKeys.length > 1),
            message: '动态检测任务只允许指定一个目标主机', // 动态监测不允许发起批量任务，否则会导致复合任务中有复合任务
          },
          {
            validator: () => selectedRowKeys.length >= 1,
            message: '请选择主机',
          },
        ]}
      >
        <Table
          size="small"
          rowKey="key"
          data={options}
          columns={columns}
          showHeader={false}
          selectOnRowClick={true}
          selectedRowKeys={selectedRowKeys}
          onSelectChange={onSelectChange}
          maxHeight={210}
          lazyLoad
          bordered
        />
      </FormItem>
      <FormItem label="任务类型" name="task_general_types">
        <Space direction="vertical">
          {<>
            <Radio.Group value={taskTypes} onChange={(v) => {
              changeTaskGeneralType(v);
            }}>
              {taskTypesSelector.map((tasktype, index) => <Radio.Button key={index} value={index}>
                {tasktype.label}
              </Radio.Button>)}
            </Radio.Group>
          </>}
        </Space>
      </FormItem>
      {taskTypesSelector[taskTypes].children ? <FormItem label="详细类型" name="task_detail_types">
        <Space direction="vertical">
          {taskTypesSelector[taskTypes].children
            ? <>
              <Radio.Group value={currentFormItemIndex} onChange={changeTaskType}>
                {taskTypesSelector[taskTypes].children.map((tasktype, index) => <Radio.Button
                  key={index} value={index}>
                  {tasktype.label}
                </Radio.Button>)}
              </Radio.Group>
            </> : null}
        </Space>
      </FormItem> : null}
      <VisFormItem visable={Form.useWatch('profiler_type', form) !== 2 && !!currentFormItemSelector.perf_wide}>
        <FormItem label="查询范围" name="perf_wide" initialData='system'
                  rules={[{ required: true, type: 'error', message: '请选择采样范围' }]} help={
          !currentFormItemSelector.java_profiler_type && !currentFormItemSelector.java_heap_task ? null : '若未指定pid，将自动执行 pgrep -n java 命令获取pid'
        }>
          <Radio.Group>
            <Radio.Button
              value="system">{!currentFormItemSelector.java_profiler_type && !currentFormItemSelector.java_heap_task ? 'System' : '最近启动的Java进程'}</Radio.Button>
            <Radio.Button value="pid">Pid</Radio.Button>
            <VisFormItem visable={!!currentFormItemSelector.callgraph
              && noUnavailableFeatures(MACHINE_FEATURES.OPTION_COMM)}>
              <Radio.Button value="comm">进程名</Radio.Button>
            </VisFormItem>
            <VisFormItem visable={!!currentFormItemSelector.callgraph
              && noUnavailableFeatures(MACHINE_FEATURES.OPTION_PORT)}>
              <Radio.Button value="port">端口</Radio.Button>
            </VisFormItem>
            <VisFormItem visable={!!currentFormItemSelector.callgraph
              && noUnavailableFeatures(MACHINE_FEATURES.OPTION_EXE)}>
              <Radio.Button value="command">可执行文件</Radio.Button>
            </VisFormItem>
          </Radio.Group>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={
        (Form.useWatch('profiler_type', form) !== 2)
        && !!currentFormItemSelector.callgraph
        && (featureEnable(currentAgent?.version, FEATURE_MINIMAL_VERSION.dwarf_lbr_callgraph_option))
      }>
        <FormItem
          label="栈回溯方式"
          name="callgraph"
          initialData='fp'
          rules={[{ required: true, type: 'error', message: '请选择栈回溯方式' }]}
          help="当gcc编译时使用--fomit-frame-pointer参数时选择dwarf">
          <Radio.Group>
            <Radio.Button value="fp">fp</Radio.Button>
            <Radio.Button value="dwarf">dwarf</Radio.Button>
          </Radio.Group>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={!!currentFormItemSelector.tracing_wide}
                  rules={[{ required: true, type: 'error', message: '请选择追踪范围' }]}>
        <FormItem label="追踪范围" name="tracing_wide" initialData='pid'>
          <Radio.Group>
            <Radio.Button value="pid">Pid</Radio.Button>
            <Radio.Button value="comm">进程名</Radio.Button>
            <Radio.Button value="command">可执行文件</Radio.Button>
          </Radio.Group>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={Form.useWatch('perf_wide', form) === 'pid' | Form.useWatch('tracing_wide', form) === 'pid'}>
        <FormItem label="Pid" name="pid" initialData={-1}
                  rules={
                    [{ pattern: /^-1$|^[1-9]\d*$/, message: '请输入正整数' }, {
                      required: true,
                      type: 'error',
                      message: '请输入pid',
                    }]
                  }>
          <Input/>
        </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={Form.useWatch('perf_wide', form) === 'comm' | Form.useWatch('tracing_wide', form) === 'comm'}>
        <FormItem label="进程名" name="comm"
                  rules={[{ pattern: /^[a-zA-Z0-9-_]+$/, message: '进程名不应有屏蔽字符' },
                    { required: true, type: 'error', message: '请输入进程名,应只对应一个进程' }]}>
          <Input/>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={Form.useWatch('perf_wide', form) === 'port' & !currentFormItemSelector.pprof_listen}>
        <FormItem label="端口" name="port"
                  rules={[{ pattern: /^-1$|^[1-9]\d*$/, message: '请输入正整数' }, {
                    required: true,
                    type: 'error',
                    message: '请输入端口',
                  }]}>
          <Input/>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={Form.useWatch('perf_wide', form) === 'command' | Form.useWatch('tracing_wide', form) === 'command'}>
        <FormItem label="执行路径" name="command"
                  rules={[
                    ...forbiddenPatterns, // 添加多个 pattern 规则
                    { pattern: /^\/[\w\-.]+(\/[\w\s\w-.]+)*\/?$/, message: '请输入正确的文件目录' },
                    { required: true, type: 'error' },
                ]}
                  help="示例：/data/temp -o output.data (“/data/temp”为可执行文件，“-o output.data”为参数)">
          <Input/>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={currentFormItemSelector.pprof_listen}>
        <FormItem label="端口" name="port" initialData={11014}
                  help="填写pprof监听端口"
                  rules={
                    [{ pattern: /^-1$|^[1-9]\d*$/, message: '请输入正整数' }, {
                      required: true,
                      type: 'error',
                      message: '请输入端口',
                    }]
                  }>
          <Input/>
        </FormItem>
      </VisFormItem>

      <VisFormItem visable={!!currentFormItemSelector.trace_object}>
        <FormItem label="内存分配器" name="trace_object" initialData={''}
                  rules={[{ pattern: /^\S*$/, message: '内存分配器位置中不能包含空格' },
                    { required: false, type: 'error', message: '请指定使用的内存分配器位置' }]}
                  help="默认使用 glibc。如果使用了第三方内存分配器 jemalloc、tcmalloc 等，请指定动态库完整路径，例如 /path/to/libjemalloc.so 注意，如果静态链接了内存分配器，请填写 Pid 对应二进制文件的完整路径。">
          <Input placeholder="glibc"/>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={currentFormItemSelector.duration}>
        {Form.useWatch('perf_wide', form) === 'command' | Form.useWatch('tracing_wide', form) === 'command'
        ? <FormItem label="时长(秒)" name="duration" initialData={1}
                  rules={[{ pattern: /^[1-9]\d*$/, message: '请输入正整数' }, {
                    required: false,
                  }]}>
          <Input placeholder="采集默认持续到进程结束,如有需求请输入时长"/>
        </FormItem>
        : <FormItem label="时长(秒)" name="duration" initialData={10}
                rules={[{ pattern: /^[1-9]\d*$/, message: '请输入正整数' }, {
                required: true,
                type: 'error',
                message: '请输入正整数',
                }]}>
              <Input/>
          </FormItem>
        }
      </VisFormItem>
      <VisFormItem visable={currentFormItemSelector.pprof_mem_leak_wide}>
      <FormItem label="时长(秒)" name="duration" initialData={0}
        help="默认为0，即采样当前时刻内存情况，设置其他数即为未来时间段内的内存变化情况。"
        rules={[{ pattern: /^[0-9]\d*$/, message: '请输入正整数' }, { required: true, type: 'error', message: '请输入pid' }]}>
        <Input />
      </FormItem>
      </VisFormItem>
      <VisFormItem visable={currentFormItemSelector.pprof_mem_leak_wide}>
      <FormItem label="源文件路径" name="pprofmemsourcepath" initialData={''}
        help="如果项目代码文件夹位置有所变动，即已经移动或删除。请填写当前可寻找到的项目文件夹绝对路径，请注意，项目文件夹名需一致。"
        rules={[{ pattern: /^\/[\w\-.]+(\/[\w\w-.]+)*\/?$/, message: '请输入正确的文件目录' }]}>
        <Input />
      </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={
          Form.useWatch('profiler_type', form) !== 2
          && !!currentFormItemSelector.profiling_freqence}
      >
        <FormItem label="频率(Hz)" name="profiling_freqence" initialData={99}
                  rules={[{ pattern: /^[1-9]\d*$/, message: '请输入正整数' }, {
                    required: true,
                    type: 'error',
                    message: '请输入pid',
                  }]}>
          <Input/>
        </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={
          Form.useWatch('perf_wide', form) === 'system'
          && (!!currentFormItemSelector.container_type)
          && (featureEnable(currentAgent?.version, FEATURE_MINIMAL_VERSION.profilingByContainer))
          && noUnavailableFeatures(MACHINE_FEATURES.OPTION_CONTAINER_MODE)}>
        <FormItem initialData={0} label="容器视角" name="container_type"
                  help="雨滴Agent需要能访问到容器运行时">
          <Switch customValue={[1, 0]} label={['开启', '关闭']}></Switch>
        </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={(!!currentFormItemSelector.annotate_enable_check)
          && (featureEnable(currentAgent?.version, FEATURE_MINIMAL_VERSION.annotate))
          && (noUnavailableFeatures(MACHINE_FEATURES.PERF_ANNOTATE_ENABLE))}>
        <FormItem initialData={false} label="热点汇编关联" name="annotate_enable"
                  help="开启后会略微影响运行速度,需要二进制文件含有Debug信息">
          <Switch customValue={[true, false]} label={['开启', '关闭']}></Switch>
        </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={(featureEnable(currentAgent?.version, FEATURE_MINIMAL_VERSION.profilingByContainer))
          && (!!currentFormItemSelector.sourcefilepath_enable)
          && (noUnavailableFeatures(MACHINE_FEATURES.PERF_SCRIPT_ENABLE))}>
        <FormItem initialData={false} label="调试信息采集" name="sourcefilepath_enable"
                  help="开启后会略微影响运行速度,需要二进制文件含有Debug信息">
          <Switch customValue={[true, false]} label={['开启', '关闭']}></Switch>
        </FormItem>
      </VisFormItem>
      <VisFormItem
        visable={(
          Form.useWatch('perf_wide', form) === 'pid'
            && !!currentFormItemSelector.record_subprocess)
          && (featureEnable(currentAgent?.version, FEATURE_MINIMAL_VERSION.record_process))
          && noUnavailableFeatures(MACHINE_FEATURES.OPTION_PID_SUBPROCESS)}>
        <FormItem initialData={false} label="子进程" name="record_subprocess"
                  help="收集Pid子进程数据">
          <Switch customValue={[true, false]} label={['开启', '关闭']}></Switch>
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={!!currentFormItemSelector.container_name && currentAgent?.version !== ''}>
        <FormItem label="容器名" name="container_name"
                  rules={[{ required: true, type: 'error', message: '请输入容器名' }]}>
          <Input/>
        </FormItem>
      </VisFormItem>
      <FormItem label="执行计划" name="schedule_type" initialData="none">
        <Radio.Group>
          <Radio.Button value="none">立即执行</Radio.Button>
          <Radio.Button value="one-time">指定时间</Radio.Button>
          <Radio.Button value="cron">循环</Radio.Button>
        </Radio.Group>
      </FormItem>
      <VisFormItem visable={Form.useWatch('schedule_type', form) === 'one-time'} style={{ marginLeft: 5 }}>
        <FormItem label="定时时间"
                  name="schedule_time"
                  rules={[
                    { required: true, type: 'error', message: '请选择执行时间' },
                    { validator: v => dayjs(v).isAfter(dayjs()), message: '请选择未来的时间' },
                  ]}>
          <DatePicker enableTimePicker allowInput clearable
                      valueType="time-stamp"
                      disableDate={{
                        before: dayjs().subtract(1, 'day')
                          .format(),
                      }}
                      timePickerProps={{ disableTime }}
          />
        </FormItem>
      </VisFormItem>
      <VisFormItem visable={Form.useWatch('schedule_type', form) === 'cron'} style={{ marginLeft: 5 }}>
        <FormItem label="Cron 表达式" name="schedule_time" help="在线生成：https://crontab.guru/" rules={[
          { validator: v => cron(v)?.isValid(), message: '请输入正确的 Cron 表达式' },
        ]}>
          <Input placeholder="请输入合法的 Cron 表达式"></Input>
        </FormItem>
      </VisFormItem>
      <FormItem initialData={1} label="提前终止" name="privilege"
                help="剩余资源不足将提前终止采集">
        <Switch customValue={[1, 0]} label={['允许', '拒绝']}></Switch>
      </FormItem>
      <FormItem style={{ marginLeft: 100 }}>
        <Button theme="primary" type="submit" style={{ marginRight: 50 }}>
          提交
        </Button>
        <Button type="reset" style={{ marginRight: 50 }}>重置</Button>
        <Button type="button" onClick={() => genCurlCommand()}>Api调用</Button>
      </FormItem>
    </Form>
    <Dialog
      closeBtn
      closeOnEscKeydown
      closeOnOverlayClick
      cancelBtn={false}
      footer
      header={
        <>
        <Text>Api调用命令</Text>
        <Link
          href={"https://iwiki.woa.com/p/4012533992"}
          size="small"
          theme="primary"
          target={'_blank'}
          style={{marginLeft: 10}}>
        帮助</Link>
        </>}
      mode="modal"
      onClose={ ()=> {
        setShowCurlCommand(false);
      }}
      confirmBtn={false}
      placement="top"
      preventScrollThrough
      showOverlay
      visible={showCurlCommand}
      theme="default"
    >
      <Text
        copyable>
      {curlCommand}</Text>
  </Dialog>
  </>
  );
};

StartTaskForm.propTypes = {
  agents: PropTypes.array,
  onClick: PropTypes.func,
  genCurlCommand: PropTypes.func,
};

export default StartTaskForm;
