/*
description:集中式路由配置
*/
import React from 'react';
import { Redirect } from 'react-router-dom';
import { HOST_URL } from '../config';
import axios from 'axios';
// const Login = React.lazy(() => import('@/pages/login')) //登陆页面
import Drop from '@/pages/drop';
// const Drop = React.lazy(() => import('@/pages/drop')) //Drop主页面
import DropIndex from '@/pages/dropIndex';
// const DropIndex = React.lazy(()=>import('@/pages/dropIndex'))
import DropUserInfo from '@/pages/dropUserInfo';
// const DropUserInfo = React.lazy(() => import('@/pages/dropUserInfo')) //配置信息页面
import AllTaskList from '@/pages/allTaskList';
// const DropManagement = React.lazy(() => import('@/pages/dropManagement')) //历史任务界面
import DropAgentList from '@/pages/dropAgentList';
// const DropAgentList = React.lazy(() => import('@/pages/dropAgentList')) //机器列表界面
import Drop404 from '@/pages/404';
// const Drop404 = React.lazy(() => import('@/pages/404')) //404页面
import DropMiniIndex from '@/pages/miniIndex';

import GeneralSampling from '@/pages/generalSampling';

import JavaSampling from '../pages/javaSampling';

import TimingSampling from '../pages/timingSampling'; // 时序性能数据分析

import FunctionAssemblyPerfReport from '../pages/functionAssemblyPerfReport'; // perf 汇编码解析

import TracingIndex from '../pages/tracing';

import Groups from '../pages/groups';

import TaskResult from '../pages/taskResult';

import FileAnalysis from '../pages/fileAnalysis';

import ScheduledTask from '../pages/scheduledTask';
import Gitlogin from '../pages/gitlogin';

import TaskCompare from '../pages/taskCompare';

const routes = [
  {
    path: '/',
    component: Drop,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => {
          const searchParams = new URLSearchParams(window.location.search);
          const refValue = searchParams.get('from');
          // 如果存在 ref 参数，则进行统计
          if (refValue) {
            // 这里可以发送统计请求到后端
            const requestParams = {
              origin: refValue,
            };
            axios({
              method: 'post',
              url: `${HOST_URL}/api/v1/user/origin`,
              params: requestParams,
            });
          }
          return <Redirect to="/index" />;
        },
      },
      { path: '/index', component: DropIndex },
      { path: '/miniIndex', component: DropMiniIndex },
      { path: '/user', component: DropUserInfo },
      { path: '/tasks', component: AllTaskList },
      { path: '/agents', component: DropAgentList },
      { path: '/tasksByAgent', component: AllTaskList },
      { path: '/generalSampling', component: GeneralSampling },
      { path: '/javaSampling', component: JavaSampling },
      { path: '/timingSampling', component: TimingSampling },
      { path: '/functionAssemblyPerfReport', component: FunctionAssemblyPerfReport },
      { path: '/tracing', component: TracingIndex },
      { path: '/groups', component: Groups },
      { path: '/task/result', component: TaskResult },
      { path: '/fileAnalysis', component: FileAnalysis },
      { path: '/scheduledTask', component: ScheduledTask },
      { path: '/taskCompare', component: TaskCompare },
      { path: '/gitlogin', component: Gitlogin },
      { component: Drop404 },
    ],
  },
  // {
  //   path: '/login',
  //   exact: true,
  //   component: Login,
  // },
  {
    component: Drop404,
  },
];

export default routes;
