/*
description:header组件
*/
import React, { memo } from 'react';
import {
  NavMenu,
  ExternalLink,
  List,
}
  from '@tencent/tea-component';
import { getOAName, clearAllCookie } from '@/utils/Storage';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { menuRouteData } from '@/common';

const header = withRouter((props) => {
  console.log('appheader------');

  // 退出登陆函数
  const quit = () => {
    clearAllCookie();
    window.location.reload();
  };

  // 渲染部分
  return <NavMenu
    left={
      <>
      <NavMenu.Item type="logo">
        <img
          src="../logo.png"
          alt="logo"
          onClick={() => {
            window.location.href = '/index';
          }}
          style={{ width: 160, height: 30 }}
        />
      </NavMenu.Item>
      {
        menuRouteData.map(item => (
          <NavMenu.Item
            style={{ fontSize: '12px' }}
            type = {item.type}
            key = {item.key}
            onClick={() => {
              document.title = `${item.title} - Drop`;
            }}
            overlay = {
              <List type="option">
              {
                item.children.map(item => <List.Item
                  key = {item.key}
                  onClick={ () => {
                    props.history.push(item.routeTo);
                  }}
                >
                    {item.title}
                </List.Item>)
              }
              </List>
            }
          >
            {item.type === 'dropdown' ? item.title
              : <NavLink
            to={item.routeTo}
          >
            {item.title}
          </NavLink>}
        </NavMenu.Item>
        ))
      }
      </>
    }
    right={
      <>
        {/* <NavMenu.Item style={{ fontSize: '12px' }}>建议</NavMenu.Item> */}
        <NavMenu.Item>
          <ExternalLink href="https://iwiki.woa.com/space/DropFAQ" >
            帮助
          </ExternalLink>
        </NavMenu.Item>
        <NavMenu.Item>
          <ExternalLink href="https://nops.woa.com/pigeon/v1/tools/add_chat?chatId=ww105450959345034" >
            进群咨询
          </ExternalLink>
        </NavMenu.Item>
        <NavMenu.Item>
        <NavLink style={{ fontSize: '12px' }} to="/groups">用户组</NavLink>
        </NavMenu.Item>
        <NavMenu.Item
          type="dropdown"
          overlay={
            <List type="option">
              <List.Item onClick={quit}>退出</List.Item>
            </List>
          }
        >
          {getOAName() || '未登录'}
        </NavMenu.Item>
      </>
    }
  />;
});

export default memo(header);
