import React, { useState, useEffect, useRef } from 'react';
import { Table, Card, Button, _SearchBox, _StatusTip, Tooltip } from '@tencent/tea-component';
import PropTypes from 'prop-types';
import EmptyTask from '../../dropIndex/emptyTask';
// import ButtonShareTask from '@/components/buttonShareTask';
import { TASK_STATUS_INFO, _TASK_TYPE_MAP, _CODE_TO_MESSAGE } from '../../../config';
import { Log } from '@/utils/Log';
import { taskTimeFormat } from '../../../utils/DateTime';
import FibonaButton from '@/components/fibona';
import { FIBONA_TRIGGER } from '@/config';
import axios from 'axios';
import { HOST_URL } from '@/config/';
// import Space from 'tdesign-react/es/space/Space';
const { pageable, _autotip, filterable } = Table.addons;
const ALL_VALUE = '__ALL__';
let funcMap = new Map();
const funcSet = new Set();
// 受控组件
const CompareTaskList = (props, ref) => {
  Log.debug('CompareTaskList', props, ref);
  // hook
  const [_inputKeyword, setInputKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [_loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskType, setTaskType] = useState(ALL_VALUE);
  const buttonRefs = useRef({});
  const inputRef = useRef(null);
  useEffect(() => {
    funcMap = props.fileContext;
    if (inputRef.current && funcMap.size > 0) {
      setTimeout(inputRef.current.onClick(), 0);
    }
  }, [props.fileContext]);

  useEffect(() => {
    setKeyword("Golang");
    setInputKeyword(keyword);
    if (props.tasks.length === 0) {
      return ;
    }
    setLoading(true);
    setTimeout(() => {
      let tempTask = props.tasks;
      if (keyword) {
        tempTask = tempTask.filter(x => x.name.includes(keyword)
            || x.target_ip.includes(keyword));
      }
      if (taskType !== ALL_VALUE) {
        tempTask = tempTask.filter(x => x.type === taskType);
      }
      setTasks(tempTask);
      setLoading(false);
    }, 800);
  }, [keyword, taskType]);

  useEffect(() => {
    let tempTask = props.tasks;
    if (keyword) {
      tempTask = tempTask.filter(x => x.name.includes(keyword)
          || x.target_ip.includes(keyword));
    }
    if (taskType !== ALL_VALUE) {
      tempTask = tempTask.filter(x => x.type === taskType);
    }
    setTasks(tempTask);
  }, [props.tasks]);

    // effect
  async function handleClick() {
      // 调用外部传入的回调函数
      props.FibonaClick();
  };
  const handleRef = (key) => {
    inputRef.current = buttonRefs.current[key]; // 保存子组件的引用
  };
  const columns = [
    {
      header: '任务名',
      key: 'name',
      align: 'center',

    },
    {
      header: '目标机器IP',
      key: 'target_ip',
      align: 'center',
      render: (record) => {
        const target = record.target_ip;
        if (target === '-') { // 复合任务可能存在多个目标主机的情况
          return <Tooltip title="该任务存在多个目标主机，请查看任务详情">{target}</Tooltip>;
        }
        Log.debug("record", record);
        return target;
      },
    },
    {
      header: '任务ID',
      key: 'uid',
      align: 'center',
      render: record => record.tid,
      width: 150,
    },
    {
      header: '新建时间',
      key: 'create_time',
      align: 'center',
      render: record => taskTimeFormat(record.create_time),
      width: 150,
    },
    {
      header: '状态',
      key: 'status',
      align: 'center',
      render: (record) => {
        Log.debug("record", record);
        const key = record.status * 10 + record.analysis_status;
        return TASK_STATUS_INFO[key];
      },

    },
    {
      header: '操作',
      key: 'action',
      align: 'center',
      render: (record) => {
      return <div style={{ display: 'flex' }}>
      <FibonaButton plain title="进行对比分析"
      trigger={FIBONA_TRIGGER.golangmem}
      data={async ()=>  await getPrompt(record.tid, props.selfTaskID)}
      FibonaClick={async ()=>  await handleClick()} // 为了确认Fibona按钮被按下了
      domref={props.domref.current}
      callback={props.callback}
      ref={(el) => (buttonRefs.current[record.tid] = el)}
      selfref={() => handleRef(record.tid)}
      />
      </div>;
      },
      width: 150,
    },
  ];

  async function getPrompt(targetTaskID, selfTaskID) {
    // setselfTaskID(selfTaskID);
    // settargetTaskID(targetTaskID);
    let prompt = "（第一个任务）\n";
    const firstprompt = await getInformationOfID(targetTaskID, 1);
    prompt = prompt + firstprompt;
    prompt = `${prompt} （第二个任务）\n `;
    const secondprompt = await getInformationOfID(selfTaskID, 2);
    prompt = prompt + secondprompt;
    prompt = `${prompt} 3.相关源代码补充:\n `;
    for (const item of funcSet) {
      if (funcMap.has(item)) {
        prompt = `${prompt}\n 函数名：${item} \n`;
        prompt = `${prompt}函数内容：${funcMap.get(item)} \n`;
      }
    }
    Log.debug("finalprompt", prompt);
    return prompt;
  };

  async function  getInformationOfID(taskID, number) {
    // 获取热点函数
    let taskresult;
    await axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/task`,
      params: {
        tid: taskID,
        is_shared_task: '0',
      } })
      .then((response) => {
        Log.debug("rresponse", response);
        taskresult = response.data.taskResult[""];
      })
      .catch((error) => {
        console.log(error);
      });
    let prompt = "\n";
    if (taskresult !== undefined) {
      const toplist = JSON.parse(taskresult.total_toplist);
      prompt = `${prompt} ${number}.1、堆内存热点函数：\n`;
      for (let i = 0;i < toplist[0].length; i++) {
        prompt = prompt + toplist[0][i];
        prompt = `${prompt} \n`;
      }
      prompt = `${prompt} ${number}.2、Goroutine热点函数：\n`;
      for (let i = 0;i < toplist[1].length; i++) {
        prompt = prompt + toplist[1][i];
        prompt = `${prompt} \n`;
      }
    }
    let requestParams = {};
    let allFucntionDicList;
    let allGoFucntionDicList;
    let callList;
    let gocallList;
    const annotateResults = '/pprof_heap_source_code_results.json';
    const goannotateResults = '/pprof_goroutine_source_code_results.json';
    const callResults = '/call_stack.json';
    const gocallResults = '/goroutine_call_stack.json';
    const cosFiles = taskID + annotateResults;
    const gocosFiles = taskID + goannotateResults;
    const callcosFiles = taskID + callResults;
    const gocallcosFiles = taskID + gocallResults;
    requestParams = {
          cos_files: [cosFiles, gocosFiles, callcosFiles, gocallcosFiles],
          tid: taskID,
          is_shared_task: '0',
    };
    await axios({
        method: 'get',
        url: `${HOST_URL}/api/v1/cosfiles`,
        params: requestParams,
      }).then(async (response) => {
        const files = response.data.cos_files;
        await axios({
          method: 'get',
          url: files[0],
        }).then((response) => {
          console.log(response);
          // setAllFucntionDicList(response);
          allFucntionDicList = response;
        });
        await axios({
          method: 'get',
          url: files[1],
        }).then((response) => {
          console.log(response);
          // setAllGoFucntionDicList(response);
          allGoFucntionDicList = response;
        });
        await axios({
          method: 'get',
          url: files[2],
        }).then((response) => {
          console.log(response);
          // setCallList(response);
          callList = response;
        });
        await axios({
          method: 'get',
          url: files[3],
        }).then((response) => {
          console.log(response);
          // setgoCallList(response);
          gocallList = response;
        });
    });
    Log.debug("targetfile", taskID, allFucntionDicList, allGoFucntionDicList, callList, gocallList);
    const array = ['runtime.', 'net/', 'internal/'];
    prompt = `${prompt} ${number}.3、堆内存相关调用栈：\n`;
    const filepath = {};
    const gofilepath = {};
    if (callList !== undefined) {
      for (let i = 0;i < callList.length & i < 5; i++) {
        prompt = `${prompt} 第${i + 1}条调用栈,在这条调用栈上分配了${callList[i][1]}堆内存:\n `;
        for (let j = 0;j < callList[i][0].length; j++) {
          // 获取分配前3的调用栈中的函数
          if (i < 2) {
            const parts = callList[i][0][j][0].split(/\/|\./);
            // 返回最后一个部分
            funcSet.add(parts.pop());
          }
          prompt = `${prompt} 函数名${callList[i][0][j][0]}\n `;
          if (callList[i][0][j][0] in filepath) {
            continue;
          } else {
            filepath[callList[i][0][j][0]] = callList[i][0][j][1];
          }
        }
        prompt = `${prompt} \n`;
      }
    }
    Log.debug("callList", callList);
    prompt = `${prompt} ${number}.4、Goroutine相关调用栈：\n`;
    if (gocallList !== undefined) {
      for (let i = 0;i < gocallList.length & i < 5; i++) {
        prompt = `${prompt} 第${i + 1}条调用栈,在这条调用栈上分配了${gocallList[i][1]}个Goroutine:\n `;
        for (let j = 0;j < gocallList[i][0].length; j++) {
          if (i < 2) {
            const parts = gocallList[i][0][j][0].split(/\/|\./);
            // Log.debug("parts.pop()", parts.pop());
            // 返回最后一个部分
            funcSet.add(parts.pop());
          }
          prompt = `${prompt} 函数名${gocallList[i][0][j][0]}\n `;
          if (gocallList[i][0][j][0] in gofilepath) {
            continue;
          } else {
            gofilepath[gocallList[i][0][j][0]] = gocallList[i][0][j][1];
          }
        }
        prompt = `${prompt} \n`;
      }
    }
    Log.debug("gofilepath", gofilepath);
    prompt = `${prompt}\n `;
    prompt = `${prompt} ${number}.5、堆内存相关函数所在位置：\n`;
    if (allFucntionDicList !== undefined) {
      for (const key in allFucntionDicList) {
        const containsAny = array.some(item => key.includes(item));
        if (containsAny) {
          continue;
        }
        if (key in filepath) {
          prompt = `${prompt} 函数：${key}，函数位置：${filepath[key]}:\n `;
        }
      }
    }
    prompt = `${prompt}\n `;
    prompt = `\n${prompt} ${number}.6、Goroutine相关函数所在位置：\n`;
    if (allGoFucntionDicList !== undefined) {
      for (const key in allGoFucntionDicList) {
        const containsAny = array.some(item => key.includes(item));
        if (containsAny) {
          continue;
        }
        if (key in gofilepath) {
          prompt = `${prompt} 函数：${key}，函数位置：${gofilepath[key]}:\n `;
        }
      }
    }
    prompt = `${prompt}\n `;
    return prompt;
  }

  return <div>
        <Card.Body
          style={{ padding: '5px', width: "760px"}}
          title="历史任务"
          subtitle={(props.viewTasks)
            ? <>
              <Button style={{ marginLeft: '20px' }} type = 'link' onClick={ () => props.viewTasks() }>历史任务详情
              </Button>
            </>
            : ''
          }
        >
        {
          (props.tasks.length !== 0) ? <div>
            <Table
          columns = { columns }
          recordKey = 'id'
          records = { tasks }
          bordered = {props.bordered}
          addons={[
            pageable({
              pageSize: props.tableRow,
              pageSizeVisible: false,
              pageCountChangingResetType: 'first',
            }),
            filterable({
              type: 'single',
              column: 'type',
              value: "Golang内存分析",
              onChange: value => setTaskType(value),
              // 增加 "全部" 选项
              all: {
                value: ALL_VALUE,
                text: '全部',
              },
              // 选项列表
              options: [
                { value: 0, text: '通用采样分析' },
                { value: 1, text: 'Java采样分析' },
                { value: 2, text: '磁盘追踪' },
                { value: 3, text: '时序分析' },
                { value: 4, text: '内存泄漏检测' },
                { value: 6, text: 'Java堆分析' },
                { value: -1, text: '动态检测' },
              ],
            }),
          ]}
        ></Table></div>
            : <EmptyTask/>}
        </Card.Body>
        <div>
      </div>
    </div>;
};

CompareTaskList.propTypes = {
  DropReducer: PropTypes.any,
  Dropaction: PropTypes.any,
  selfTaskID: PropTypes.any,
  tasks: PropTypes.array, // agent信息
  onSearchTask: PropTypes.func,  // 搜索任务
  viewTask: PropTypes.func,  // 查看任务
  viewTasks: PropTypes.func, // 历史任务详情
  deleteTask: PropTypes.func, // 删除任务
  FibonaClick: PropTypes.func, // Fibona触发
  style: PropTypes.object,
  tableRow: PropTypes.number,
  bordered: PropTypes.any,
  domref: PropTypes.object,
  callback: PropTypes.func,
  uid: PropTypes.string,
  selectProjectValue: PropTypes.string,
  selectBranchValue: PropTypes.string,
  fileContext: PropTypes.object,
};


CompareTaskList.defaultProps = {
  tableRow: 4,
  bordered: false,
};
export default CompareTaskList;
