// 内存泄漏检测结果展示
import React, { useEffect, useState } from 'react';
import {
  Card,
  Layout,
  Table,
  Button,
  Modal,
  Tag,
  Bubble,
  Icon,
  Text,
} from '@tencent/tea-component';
import {
  MessagePlugin,
  Tree,
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { TaskInfoCard } from '@/components/taskInfo';
import './style.css';
import { HOST_URL } from '../../../config';
import axios from 'axios';

const { Body, Content } = Layout;
const { pageable, sortable } = Table.addons;
const { filterable } = Table.addons;
const offsetRegex = /\+0x[0-9a-fA-F]+/g;
const MemleakResult = (props) => {
  Log.debug('MemleakResult', props);

  return <>
      <Layout className="demo-layout">
        <Body>
          <Content>
          {props.showGobackButton
            ? <Content.Header
                showBackButton
                onBackButtonClick={() => props.goback()}
                title="返回"
              >
            </Content.Header> : ''}
          <Content.Body>
            <TaskInfoCard
              task_info = {props.task_info}
              jupyter_url = {props.jupyter_url}
              analysis_version = {props.analysis_version}
            ></TaskInfoCard>
            <Card>
              <Card.Body
              title="任务结果"
              >
                <MemleakResultTab
                  memleak_results={props.memleak_results}
                  duration={props.task_info.request_params.perf_record_duration}
                ></MemleakResultTab>
              </Card.Body>
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  </>;
};

MemleakResult.propTypes = {
  task_info: PropTypes.object,
  goback: PropTypes.func,
  jupyter_url: PropTypes.string,
  memleak_results: PropTypes.array,
  analysis_version: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

MemleakResult.defaultProps = {
  jupyter_url: '',
  memleak_results: [],
  analysis_version: '1.0.0',
};

function matchCallStack(stack, targetStack) {
  if (!targetStack) {
    return false;
  }
  const stackWithoutOffset = stack.replace(offsetRegex, '');
  return targetStack.includes(stackWithoutOffset);
}

const MemleakResultTab = (props) => {
  const [sorts, setSorts] = useState([]);
  const [showCallTreeStatus, setShowCallTreeStatus] = useState(false);
  const [callTreeData, setCalltreeData] = useState([]);
  const [memoryWhiteList, setMemoryWhiteList] = useState([]);
  const [filterRule, setFilterRule] = useState('all');

  useEffect(() => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/user/memorywhitelist`,
    }).then((response) => {
      if (response?.code === 0 && response.data?.whitelist) {
        setMemoryWhiteList(response.data?.whitelist);
      } else {
        MessagePlugin.error({
          content: '白名单数据请求失败',
        });
      }
    });
  }, []);

  Log.debug('MemleakResultTab', props);

  let result = <>
    <Text theme="success">暂未发现泄漏点</Text>
  </>;

  const avgSorter = (first, second) => {
    const firstspeed = first.mem_used / first.call_time;
    const secondspeed = second.mem_used / second.call_time;
    if (firstspeed > secondspeed) {
      return 1;
    }
    if (firstspeed < secondspeed) {
      return -1;
    }
    return 0;
  };

  const memleakData = props.memleak_results.map((result) => {
    const matched = result.call_stacks.some(target => matchCallStack(target, memoryWhiteList));
    console.log(matched);
    return { ...result, mem_used: result.mem_used / 1024, matched: matched ? '已标记' : '' };
  })
    .filter(a => a.call_time !== 0)
    .sort((first, second) => (first.mem_used > second.mem_used ? -1 : 1));

  if (props.memleak_results.length !== 0) {
    let records = [...memleakData.filter(a => a.call_time !== 0)].sort(sortable.comparer(sorts));
    if (filterRule === 'marked') {
      records = records.filter(r => r.matched === '已标记');
    } else if (filterRule === 'unmarked') {
      records = records.filter(r => r.matched === '');
    }
    result =  <>
    <Text theme="warning">发现{props.memleak_results.length}处分配未释放点,共计
    {memleakData.reduce((a, b) => a + b.mem_used, 0).toString()}KB,请关注</Text>
    <Table
      verticalTop
      records={records}
      columns={[
        {
          key: 'func',
          header: () => (
            <>
              可能泄漏函数名
              <Bubble content="点击函数名查看调用栈">
                <Icon type="info" />
              </Bubble>
            </>
          ),
          render: result => <>
            <Button type='link'
              onClick={() => {
                setShowCallTreeStatus(true);
                setCalltreeData(result.call_stacks);
              }}
            >{ (String(result.func).length >= 40)
              ? `${String(result.func).slice(0, 40)}...` : String(result.func) }</Button>
            { result.mem_used / props.duration > 10000
              ? <Tag theme="error">疑似泄露较多</Tag> : ''}
            </>,
        },
        {
          key: 'matched',
          header: '标记为无泄漏',
          width: 120,
        },
        {
          key: 'mem_used',
          header: '泄漏内存大小(KB)',
        },
        {
          key: 'call_time',
          header: '调用次数(次)',
        },
        {
          key: 'avg_memleak_speed',
          header: '平均泄漏大小(KB/次调用)',
          render: result => result.mem_used / result.call_time,
        },
      ]}
      addons={[pageable(),
        sortable({
          // 这两列支持排序，其中 age 列优先倒序，mail 采用自定义排序方法
          columns: [
            {
              key: 'mem_used',
              prefer: 'desc',
            },
            {
              key: 'call_time',
              prefer: 'desc',
            },
            {
              key: 'avg_memleak_speed',
              prefer: 'desc',
              sorter: avgSorter,
            },
          ],
          value: sorts,
          onChange: value => setSorts(value),
        }),
        filterable({
          type: 'single',
          column: 'matched',
          value: filterRule,
          onChange: value => setFilterRule(value),
          // 增加 "全部" 选项
          all: {
            value: 'all',
            text: '全部',
          },
          // 选项列表
          options: [
            { value: 'marked', text: '已标记' },
            { value: 'unmarked', text: '未标记' },
          ],
        }),
      ]}
    />
    <Modal size="auto" visible={showCallTreeStatus} caption="调用栈" onClose={() => setShowCallTreeStatus(false)}>
        <Modal.Body>
          <CallStackGraph
            call_stacks={callTreeData}
            memory_whitelists={memoryWhiteList}
            onWhiteListChanged={(event) => {
              setMemoryWhiteList(event);
            }}
          ></CallStackGraph>
        </Modal.Body>
      </Modal>
  </>;
  }
  return result;
};

MemleakResultTab.propTypes = {
  memleak_results: PropTypes.array,
  duration: PropTypes.number,
};

// 展示调用图组件
export const CallStackGraph = (props) => {
  Log.debug('callStackGraph', props);

  const toTreeDataItem = (label) => {
    const replaced = label.replace(offsetRegex, '');
    const marked = props.memory_whitelists?.includes(replaced);
    const requestParams = {
      operation: marked ? 'del' : 'add',
      function: replaced,
    };
    return (
      <div className="hover-reveal">
        <span className={marked ? 'marked-text' : ''}>{label}</span>
        <span className="hidden-text" onClick={() => {
          axios({
            method: 'post',
            url: `${HOST_URL}/api/v1/user/memorywhitelist`,
            data: requestParams,
          }).then((response) => {
            if (response?.code === 0) {
              props.onWhiteListChanged(response.data?.whitelist);
            } else {
              MessagePlugin.error({
                content: '白名单数据请求失败',
              });
            }
          });
        }}>{marked ? '取消标记' : '标记为无泄漏'}</span>
      </div>
    );
  };

  const changeArrayToTreeData = (data) => {
    const treeData = [{
      value: 0,
      label: toTreeDataItem(data[0]),
      children: [],
    }];
    let temp = treeData[0].children;
    for (let i = 1; i <= data.length - 2; ++i) {
      temp.push({
        value: i,
        label: toTreeDataItem(data[i]),
        children: [],
      });
      temp = temp[0].children;
    }
    temp.push({
      value: data.length - 1,
      label: toTreeDataItem(data[data.length - 1]),
    });
    return treeData;
  };

  return <>
    <Tree data={changeArrayToTreeData(props.call_stacks)} line={true} expandAll></Tree>
  </>;
};

CallStackGraph.propTypes = {
  call_stacks: PropTypes.object,
  memory_whitelists: PropTypes.array,
  onWhiteListChanged: PropTypes.func,
};

export default MemleakResult;
