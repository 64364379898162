import React, { useEffect, useState, useRef } from 'react';
import '../../Drop.css';
import qs from 'qs';
// import Callgraph from './callgraph';
// import FlameGraph from '@/components/flamegraph/flamegraph';
import {
  Bubble,
  Card, Icon,
  _Layout,
  TabPanel,
  Tabs,
  Text,
  message,
} from '@tencent/tea-component';
import {
  Dialog, Guide, Comment,
  Tooltip as _TDTooltip,
  List, _Popup, Select,
  Space, Popconfirm, Table,
  Button, Collapse, _TagInput,
  Link, Tag, _Tooltip, _Statistic,
} from 'tdesign-react';
import PropTypes, { _string } from 'prop-types';
import { Log } from '@/utils/Log';
import axios from 'axios';
import { GongFengHOST, HOST_URL } from '@/config/';
// import { _getgoroutineFlameGraphURL, getFlameGraphURL,
// _getheapFlameGraphURL, _downloadFile, jumpTargetConfig } from '@/utils/URL';
import GongFengButton from '../../../components/gongfengButton';
import { SearchIcon, _NotificationErrorIcon, _CloseCircleFilledIcon, _ChevronDownIcon } from 'tdesign-icons-react';
import _AnchorItem from 'tdesign-react/es/anchor/AnchorItem';
import CompareTaskList from './compareList';
import { getOAName, getUserUid } from '@/utils/Storage';
import { goodStatus } from '@/utils/ErrorConfirm';
const { ListItem } = List;

const steps = [
  {
    element: '.t-collapse',
    title: '分析结果',
    body: '在分析结果中可以看到堆内存以及Goroutine最有可能出现问题的部分。通过折叠面板的下拉可以看到最多分配量的调用栈以及调用栈中各个函数的分配量。',
    placement: 'top-right',
    stepOverlayClass: 't-test-guide-step-overlay',
  },
  {
    element: '.more-info',
    title: '补充信息',
    body: '在补充信息的区域，可以快速了解堆内存以及Goroutine各自的调用图以及热点函数情况',
    placement: 'top-right',
  },
  {
    element: '.AIcompare',
    title: 'AI对比',
    body: '在AI对比功能中，通过选择另一个任务，可以快速生成两个任务之间的对比报告，AI会告诉你代码中可能存在的问题以及一些修复建议。特别适合新版本上线以及线上问题排查。',
    placement: 'right',
  },
  {
    element: '.gongfeng-button',
    title: '工蜂联动功能',
    body: '当生成AI建议后，可以通过工蜂联动功能，快速跳转到工蜂，并生成一条建议评论，同时依赖工蜂copilot的能力一键生成AI代码修复意见，快速省事。',
    placement: 'right',
  },
];

const gongfengsteps = [
  {
    element: '.gongfeng-button',
    title: '工蜂联动',
    body: '已经生成AI建议了，试一试工蜂联动功能，一键生成修复代码吧！',
    placement: 'top-right',
  },
];


// 内存字符串转换为数字，便于比较和合并
function convert2num(heap) {
  if (heap == '0' | heap == undefined) {
    return 0;
  }
  const firstNumber = heap.toString().match(/\d+(\.\d+)?/);
  let num = 1;
  if (heap.includes('-')) {
    num = -1;
  }

  if (heap.includes('kB') | heap.includes('KB')) {
    return firstNumber[0] * num;
  }
  if (heap.includes('mB') | heap.includes('MB')) {
    return firstNumber[0] * 1024 * num;
  }
  if (heap.includes('gB') | heap.includes('GB')) {
    return firstNumber[0] * 1024 * 1024 * num;
  }
  if (heap.includes('tB') | heap.includes('TB')) {
    return firstNumber[0] * 1024 * 1024 * 1024 * num;
  }
  return heap;
}

// 数字转换成内存字符串，便于展示
function convert2str(heap) {
  let Heap = heap;
  if (Heap < 0) {
    Heap = Heap * -1;
  }
  if (Heap < 1024) {
    return heap.toString().concat('KB');
  }
  if (Heap < 1024 * 1024) {
    return (heap / 1024).toFixed(2).concat('MB');
  }
  if (Heap < 1024 * 1024 * 1024) {
    return (heap / (1024 * 1024)).toFixed(2).concat('GB');
  }
  if (Heap < 1024 * 1024 * 1024 * 1024) {
    return (heap / (1024 * 1024 * 1024)).toFixed(2).concat('TB');
  }
}

function indexof(List, element) {
  for (let i = 0;i < List.length; i++) {
    if (List[i].length !== element.length) {
      continue;
    }
    let judge = i;
    for (let j = 0;j < List[i].length;j++) {
      if (List[i][j][0] !== element[j][0]) {
        judge = -1;
        break;
      }
    }
    if (judge !== -1) {
      return judge;
    }
  }
  return -1;
}
function getheapvalue(number) {
  if (number == 0) {
    return <u></u>;
  }
  let judge = 1;
  if (number < 0) {
    number = number * -1;
    judge = -1;
  }
  if (number > 10 * 1024) {
    return <u>({(judge * number / 1024).toFixed(2)}MB)</u>;
  } if (number <= 10 * 1024) {
    return <u>({number}KB)</u>;
  }
}
const GolangHeapResultCard = (props) => {
  const [username] = useState(getOAName());
  const [tasks, setTasks] = useState([]);
  const [current, setCurrent] = React.useState(-1);
  const [gongfengstepcurrent, setgongfengstepcurrent] = React.useState(-1);
  const [showSuggestion, setshowSuggestion] = React.useState(false);
  const [guideStatus, setguideStatus] = useState(false);
  const [allFucntionDicList, setAllFucntionDicList] = useState({}); // 热点函数源代码列表
  const [allGoFucntionDicList, setAllGoFucntionDicList] = useState({});
  const [callList, setCallList] = useState([]);// 调用栈信息
  const [gocallList, setgoCallList] = useState([]);

  const CodeStyles = `
  <style>
 .title {
   font-weight: 500;
   font-size: 14px;
 }
 .describe {
   margin-top: 8px;
   font-size: 12px;
   color: var(--td-text-color-secondary);
 }
 </style>
 `;
 const buttonRef = useRef(null);
 const childRef = useRef(null);
 // TODO：设计成通用的组件化
 const [selectProjectValue, setSelectProjectValue] = useState('');
 const [selectBranchValue, setSelectBranchValue] = useState('');
 const [Projects, setProjects] = useState([]);
 const [Branches, setBranches] = useState([]);
 const [sourceFilePath, setSourceFilePath] = useState([]);

const [funcMap, setfuncMap] = useState(new Map());
const updatefuncMap = (key, value) => {
  setfuncMap(prevMap => {
    const newMap = new Map(prevMap);
    newMap.set(key, value);
    return newMap;
  });
};

  // 存放项目的目录
 const [tree, setTree] = useState([]);
 useEffect(() => {
     // 这里放置你想要执行的操作
     const requestParams = {
       uid: getUserUid(),
     };
     axios({
       method: 'get',
       url: `${GongFengHOST}/api/v1/gongfeng/projects`,
       params: requestParams,
     }).then((response) => {
       if (response !== undefined & response.data.projects.length !== 0) {
         const projectsArray = Object.entries(response.data.projects).map(([id, info]) => ({
           label: info.name_with_namespace,
           value: Number(id),
           updated_at: info.updated_at,
           name: info.name,
         }));
         projectsArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
         const projectOPTIONS = projectsArray.map(({ label, value }) => ({ label, value }));
         setProjects(projectOPTIONS);
       }
     });
 }, []);
// 获取目录用于拼接文件路径
useEffect(() => {
  let requestParams = {
    projectID: selectProjectValue,
    uid: getUserUid(),
    branchName: "",
  };
  axios({
    method: 'get',
    url: `${GongFengHOST}/api/v1/gongfeng/tree`,
    params: requestParams,
  }).then((response) => {
    setTree(response.data.tree);
  });

  requestParams = {
    projectID: selectProjectValue,
    uid: getUserUid(),
  };
  axios({
    method: 'get',
    url: `${GongFengHOST}/api/v1/gongfeng/branches`,
    params: requestParams,
  }).then((response) => {
    const branchOPTIONS = [];
    for (const [key, value] of Object.entries(response.data.branches)) {
      branchOPTIONS.push({
        label: value,
        value: key,
      });
    }
    setBranches(branchOPTIONS);
  });
}, [selectProjectValue]);

useEffect(() => {
  if (funcMap.size == 0) {
    for (let i = 0 ; i < sourceFilePath.length ; i++) {
      const requestParams = {
        projectID: selectProjectValue,
        uid: getUserUid(),
        commitSha: selectBranchValue,
        filePath: sourceFilePath[i],
      };
      axios({
        method: 'get',
        url: `${GongFengHOST}/api/v1/gongfeng/file`,
        params: requestParams,
      }).then((response) => {
        if (response.data.fila_content) {
          for (let j = 0; j < response.data.fila_content.length; j++) {
            updatefuncMap(response.data.fila_content[j][0], response.data.fila_content[j][1]);
          }
        }
      });
    }
  }
}, [selectBranchValue, sourceFilePath]);

useEffect(() => {
let k;
const filelist = [];
for (k = 0; k < 2; k++) {
  let List;
  if (k == 0) {
    List = callList;
  } else {
    List = gocallList;
  }
  if (List.length == 0) {
    continue;
  }
  const valuesList = Object.values(List);
  if (k == 0) {
    valuesList.sort((a, b) => Math.abs(convert2num(b[1])) - Math.abs(convert2num(a[1])));
  } else {
    valuesList.sort((a, b) => b[1] - a[1]);
  }
  for (let i = 0; i < List.length; i++) {
    for (let k = 0; k < List[i][0].length; k++) {
      let index = -1;
      const filepath = List[i][0][k][1];
      const slice = filepath.split(/[/|:]/);
      let topfilename = "";
      // 遍历文件路径
      for (let j = 0; j < slice.length; j++) {
        if (slice[j] === '') {
          continue;
        } else {
          // 找到第一个属于当前项目目录的文件名
          if (tree.includes(slice[j])) {
            topfilename = slice[j];
            break;
          }
        }
      }
      if (topfilename !== "") {
        index = filepath.indexOf(topfilename);
      }
      if (index !== -1) { // 定位到相应的文件路径，进行行号等拆分
        const temp = filepath.substring(index);
        const temp2 = temp.split(":");
        if (!filelist.includes(temp2[0])) {
          filelist.push(temp2[0]);
        }
      }
    }
  }
}
setSourceFilePath(filelist);
}, [tree]);

 useEffect(() => {
   // 添加示例代码所需样式
   document.head.insertAdjacentHTML('beforeend', CodeStyles);
 }, []);
  // effect
  // init
  useEffect(() => {
    queryTasks();
    const requestParams = {
      task_type: 1,
    };
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/user/guide/status`,
      params: requestParams,
    }).then((response) => {
      if (response.data) {
        if (response.data.status == 0) {
          setguideStatus(true);setTimeout(() => {}, 100);
        }
        Log.debug('response.data.suggestion  setguideStatus', guideStatus, response.data.status);
      }
    });
  }, []);

  useEffect(() => {
    if (guideStatus) {
      setCurrent(0); // 启动引导
      setgongfengstepcurrent(0);// 开启工蜂联动功能的引导
    }
  }, [guideStatus]);

  useEffect(() => {
    const requestParams = {
      task_id: qs.parse(location.search.slice(1)).taskID,
    };
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/ai/suggestion`,
      params: requestParams,
    }).then((response) => {
      // 此处不处理超时情况，超时刷新的判断在apisever中进行
      if (response.data.suggestion) { // 有分析建议，需要二次展示
        Log.debug('response.data.suggestion', response);
        setsuggestion(JSON.parse(response.data.suggestion));
        if (response.data.url) {
          seturl(JSON.parse(response.data.url));
        }
        setshowSuggestion(true);
        setgongFengButtonVisible(true);
      }
    });
  }, []);

  const queryTasks = () => {
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/tasks`,
      params: {
        user: username,
      } })
      .then((response) => {
        if (goodStatus(response)) {
          setTasks(response.data.tasks.sort((a, b) => Date.parse(b.create_time.Time) - Date.parse(a.create_time.Time)));
        } else {
          message.error({
            content: '用户信息获取失败,请重试',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let forindex = 0;
  // 基于传入数据生成tabs
  const tabs = [];
  // 分析建议永远显示，如果没有建议也会显示"暂无建议"
  tabs.push({
    id: 'suggestions', label: <div>
      <Text>分析建议 </Text>
      <Bubble
          arrowPointAtCenter
          content="分析流程已去除swapper等无效数据"
      ><Icon type="info" style={{ marginBottom: 3 }}/></Bubble>
    </div>,
  });
  // top_funcs为字典，包含函数名和【分配量、分配占比】
  const topNData = JSON.parse(props.top_funcs);
  const goroutineTopNData = JSON.parse(props.goroutine_top_funcs);
  const toplist = JSON.parse(props.total_toplist);
  Log.debug('www', goroutineTopNData, topNData);

  // 添加建议展示
  // TODO(alexjyliu): 未来可能展示更多的建议字段（分类、优先级）
  // const suggestions = [];
  const Collapsedata = [];
  const { Panel } = Collapse;
  if (props.analyse_suggestions?.length > 0) {
    for (let index = 0; index < props.analyse_suggestions.length; index++) {
      forindex = forindex + 1;
      const dictionary = {};
      const suggestion = props.analyse_suggestions[index];
      dictionary.id = index;
      if (suggestion.suggestion.includes('goroutine')) {
        dictionary.filetype = 1;
      } else {
        dictionary.filetype = 0;
      }
      dictionary.funcName = suggestion.func;
      if (dictionary.funcName == 'None') {
        Collapsedata.push(dictionary);
        continue;
      }
      dictionary.number = suggestion.number;
      if (!suggestion?.suggestion?.includes('goroutine')) {
        dictionary.children = [{ number: topNData[suggestion.func].self,
          percent: topNData[suggestion.func]['self%'] }];
      } else {
        Log.debug('wwww', goroutineTopNData, suggestion.func);
        dictionary.children = [{ number: goroutineTopNData[suggestion.func].self,
          percent: goroutineTopNData[suggestion.func]['self%'] }];
      }
      Collapsedata.push(dictionary);
    }
  }

  // 如果存在火焰图搜索query，则该页面来自其他页面的跳转，直接切换至火焰图tab
  const initActiveTab = tabs[0].id;
  const callgraphFile = props.callgraph_file;
  const goroutineCallgraphFile = props.goroutine_callgraph_file;
  // 填充对应函数的源代码
  function TableBasic(data) {
    const table = (
      <Table
        data={data}
        footData={[{}]}
        tableLayout='fixed'
        maxHeight={400}
        size='small'
        fixedRows={undefined}
        bordered
        stripe
        whiteSpace = 'pre'
        columns={[
          { colKey: 'percent', title: '百分比', width: 60, foot: '-' },
          { colKey: 'flet', title: '函数自身分配', width: 90, foot: '-', ellipsis: true },
          { colKey: 'cum', title: '函数及其所有被调用函数分配', width: 120, foot: '-', ellipsis: true },
          // { colKey: 'linenumber', title: '行号', width: 100, foot: '-', ellipsis: true },
          { colKey: 'code', title: '源代码', width: 500, foot: '-', ellipsis: { props: {
            theme: 'light',
            placement: 'bottom-right',
          },
          }, whiteSpace: 'pre' },
        ]}
        rowKey="index"
        lazyLoad
      />
    );
    return table;
  }

  // 用于渲染折叠列表中的面板部分，包含调用栈、相关信息
  function renderPanels() {
    const panels = [];
    let sourcecodestatus = 1;

    const args = qs.parse(location.search.slice(1));
    const [taskID] = useState(args.taskID ?? props.tid);
    const [taskExpiredTime] = useState(Number(args.taskExpiredTime));
    const [sharedTaskToken] = useState(args.sharedTaskToken);
    const [isSharedURL] = useState(args.isSharedURL);

    useEffect(() => {
      let requestParams = {};
      let annotateResults = ' ';
      let goannotateResults = ' ';
      let callResults = ' ';
      let gocallResults = ' ';
      annotateResults = '/pprof_heap_source_code_results.json';
      goannotateResults = '/pprof_goroutine_source_code_results.json';
      callResults = '/call_stack.json';
      gocallResults = '/goroutine_call_stack.json';
      const cosFiles = taskID + annotateResults;
      const gocosFiles = taskID + goannotateResults;
      const callcosFiles = taskID + callResults;
      const gocallcosFiles = taskID + gocallResults;
      if (isSharedURL === 'true') {
        requestParams = {
          cos_files: [cosFiles, gocosFiles, callcosFiles, gocallcosFiles],
          tid: taskID,
          expired_time: taskExpiredTime,
          token: sharedTaskToken,
          is_shared_task: '1',
        };
      } else {
        requestParams = {
          cos_files: [cosFiles, gocosFiles, callcosFiles, gocallcosFiles],
          tid: taskID,
          is_shared_task: '0',
        };
      }
      axios({
        method: 'get',
        url: `${HOST_URL}/api/v1/cosfiles`,
        params: requestParams,
      }).then((response) => {
        const files = response.data.cos_files;
        axios({
          method: 'get',
          url: files[0],
        }).then((response) => {
          console.log(response);
          setAllFucntionDicList(response);
        });
        axios({
          method: 'get',
          url: files[1],
        }).then((response) => {
          console.log(response);
          setAllGoFucntionDicList(response);
        });
        axios({
          method: 'get',
          url: files[2],
        }).then((response) => {
          console.log(response);
          setCallList(response);
        });
        axios({
          method: 'get',
          url: files[3],
        }).then((response) => {
          console.log(response);
          setgoCallList(response);
        });
      });
    }, []);
    if ('main.main' in allFucntionDicList & 'main.main' in allGoFucntionDicList) {
      if (Object.keys(allFucntionDicList['main.main'].asmCodeArray).length !== 0
      | Object.keys(allGoFucntionDicList['main.main'].asmCodeArray).length !== 0
      ) {
        sourcecodestatus = 1;
      } else {
        sourcecodestatus = 0;
      }
    }

    // 获取相关业务代码信息，用于给出直接原因
    function getRebusinessFuncInfo(funcName, filetype, item, funclist) {
      const RebusinessFunc = [];
      const RebusinessFuncFile = [];
      let List = [];
      List = funclist;
      for (let i = 0;i < List.length ;i++) {
        if (judge(funcName, List[i])) {
          const len = List[i].length;
          if (List[i][len - 1][0] === 'runtime.main' && List[i].length > 1) {
            RebusinessFunc.push(List[i][len - 2][0]);
            RebusinessFuncFile.push(List[i][len - 2][1]);
            continue;
          }
          RebusinessFunc.push(List[i][len - 1][0]);
          RebusinessFuncFile.push(List[i][len - 1][1]);
        }
      }
      const datalist = getTableData(RebusinessFunc[0], filetype);
      // 获取源头函数
      const sourcefunc = `${RebusinessFunc[0]}`;
      // 当前分配最多函数
      const func = `${item.funcName}`;
      return <>
        {
          filetype === 0
            ? <Tag size="mid" theme="danger" variant="light">堆内存分配:</Tag>
            : <Tag size="mid" theme="danger" >疑似Goroutine泄漏:</Tag>
        }
        <Tag size="mid" variant="light" maxWidth={'350px'}
        style={{ padding: '0px', marginLeft: '5px' }}
        title={sourcefunc}>
        函数<span style={{ color: 'black' }}><b>{RebusinessFunc[0]}</b></span></Tag>
        {
          filetype === 0
            ? <Tag size="mid" variant="light" maxWidth={'200px'}
          style={{ padding: '0px' }}
          title={func}>
          最终调用<span style={{ color: 'black' }}><b>{item.funcName}</b></span></Tag>
            : datalist[3] === ''
              ? <Tag size="mid" variant="light" maxWidth={'200px'}
            style={{ padding: '0px' }}
            title={func}>
            可能存在阻塞</Tag>
              : <Tag size="mid" variant="light" maxWidth={'200px'}
            style={{ padding: '0px' }}
            title={func}>
            中的<b><span style={{ color: 'red' }}>channel {datalist[3]}</span></b>阻塞</Tag>
        }
        {
          filetype === 0
            ? <Tag size="mid" variant="light"
          style={{ padding: '0px' }}>
          造成了<span style={{ color: 'red' }}><b>{item.children[0].number}</b></span>的分配量,占比达到
          <span style={{ color: 'red' }}><b>{item.children[0].percent}%</b></span>
          </Tag>
            : <Tag size="mid" variant="light"
          style={{ padding: '0px' }}>
          导致Goroutine数量达到<span style={{ color: 'red' }}><b>{item.children[0].number}</b></span></Tag>
        }
        {
          RebusinessFuncFile.length <= 1
            ? sourcecodestatus === 1
              ? <Popconfirm theme={'default'} content={
            (<>
            <Tag
            style={{ marginBottom: '10px' }}
            size="mid" theme="warning" variant="light">函数所在的文件位置：{RebusinessFuncFile[0]}</Tag>
            <Space direction="vertical">
            {
              getTableData(RebusinessFunc[0], filetype)[0].length !== 0
                ? TableBasic(getTableData(RebusinessFunc[0], filetype)[0])
                : <Tag
              style={{ marginBottom: '10px' }}
              size="mid" theme="warning" variant="light">当前环境无法访问到该文件路径，可以尝试提供可访问到的文件路径</Tag>
            }
            </Space>
            </>)
          } popupProps={{
            placement: 'bottom',
            overlayStyle: { width: '870px', maxWidth: '870px' } }}
            confirmBtn={<></>}
            cancelBtn={<></>}
            >
              <Button
              size="small"  theme="default" variant="text"
              >
                <u>查看源码</u>
              </Button>
              </Popconfirm>
              : <></>
            : <Tag size="mid" variant="light"
            style={{ padding: '0px' }}>
            <span style={{ color: 'black' }}>[存在多条调用栈,展开查看详情]</span></Tag>
        }
          </>;
    }

    // 用于获取调用栈并为每个调用栈中的函数提供按钮即对应弹窗
    function GetButtonList(functionName, List, filetype) {
      const ButtonList = [];
      const callList = List[0];
      for (let j = callList.length - 1; j >= 0; j--) {
        ButtonList.push(<>
        {/* <Button size="small">{callList[j]}</Button> */}
        <Popconfirm theme={'default'} content={
        (
        <>
        <Tag
        style={{ marginBottom: '10px' }}
        size="mid" theme="warning" variant="light">函数{callList[j][0]}的代码详情</Tag>
        <Tag
        style={{ marginBottom: '10px' }}
        size="mid" theme="warning" variant="light">函数所在的文件位置：{callList[j][1]}</Tag>
        <Space direction="vertical">
        { callList[j][0] in allFucntionDicList | callList[j][0] in allGoFucntionDicList
          ? getTableData(callList[j][0], filetype)[0] != 0
            ? TableBasic(getTableData(callList[j][0], filetype)[0])
            : <Tag
            style={{ marginBottom: '10px' }}
            size="mid" theme="warning" variant="light">当前环境无法访问到该文件路径，可以尝试提供可访问到的文件路径</Tag>
          : <Tag
          style={{ marginBottom: '10px' }}
          size="mid" theme="warning" variant="light">当前函数并非热点函数，并未获取源码信息</Tag>
        }
        </Space>
        </>
        )
      } popupProps={{
        placement: 'bottom',
        overlayStyle: { width: '870px', maxWidth: '870px' } }}
        confirmBtn={
          <></>
        }
        cancelBtn={
          <></>
        }
        >
          {
           !(callList[j][0] in allFucntionDicList | callList[j][0] in allGoFucntionDicList) // 当前函数不存在源代码
             ? <Button
          size="small" variant="outline" theme="primary" shape="round"
          // onClick={onClickConfirm }
          ><u>
          {callList[j][0]}
          </u></Button>
             : <Button // 当前函数为热点函数，可能存在源码
          size="small"  theme="warning" color="#ffd9c2" variant="outline" shape="round"
          // onClick={onClickConfirm }
          ><u>{callList[j][0]}
          {
              filetype === 0
                ? getheapvalue(getTableData(callList[j][0], filetype)[1])
                : getTableData(callList[j][0], filetype)[1] !== 0
                  ? <u>({getTableData(callList[j][0], filetype)[1]})</u>
                  : <></>
          }
          </u>
          </Button>
          }
          </Popconfirm>
        </>);
        if (callList[j][0] !== functionName) {
          ButtonList.push(<p style={{ marginTop: '1px' }}>➤</p>);
        } else {
          break;
        }
      }
      return ButtonList;
    }
    // 判断该调用栈是否包含该函数
    function judge(funcName, callList) {
      if (funcName === callList[0][0]) {
        return true;
      }
      return false;
    }

    // 提供多个调用栈的区域 并返回需要展示多少个调用栈
    function SetButtonList(funcName, filetype) {
      const result = [];
      const ButtonList = [];
      let Index = 1;
      let List = [];
      const sourcefunc = [];
      if (filetype === 0) {
        List = callList;
      } else {
        List = gocallList;
      }
      // 从大到小对调用栈进行排序
      const valuesList = Object.values(List);
      if (filetype == 0) {
        valuesList.sort((a, b) => Math.abs(convert2num(b[1])) - Math.abs(convert2num(a[1])));
      } else {
        valuesList.sort((a, b) => b[1] - a[1]);
      }

      // 筛选并合并重复的调用栈
      const stacklist = [];
      const stacknum = [];
      let index = -1;
      for (let i = 0; i < valuesList.length; i++) {
        if (judge(funcName, valuesList[i][0])) {
          index = indexof(stacklist, valuesList[i][0]);
          if (index !== -1) {
            stacknum[index] = convert2str(parseFloat(convert2num(stacknum[index]))
            + parseFloat(convert2num(valuesList[i][1])));
          } else {
            stacklist.push(valuesList[i][0]);
            stacknum.push(valuesList[i][1]);
          }
        }
      }
      ButtonList.push(<Space style={{ marginBottom: '5px', display: 'block' }}> <span style={{ fontSize: 'smaller' }}>Tips:橙色代表该函数为热点函数</span></Space>);
      for (let i = 0; i < stacklist.length; i++) {
        // 获取对应的调用栈
        if (Index > 5) {
          ButtonList.push(<hr/>);
          ButtonList.push(<Tag
              style={{ marginRight: '10px', marginBottom: '0px' }}
              size="small"  variant="text" theme="default"
              >调用栈过多，仅展示内存占比最大的5条</Tag>);
          break;
        }
        // 输出调用栈的源头函数，用于给折叠列表的表头展示
        sourcefunc.push(stacklist[i]);
        if (Index !== 1) {
          ButtonList.push(<hr/>);
        }
        ButtonList.push(<>
            <Space breakLine={true}
            maxWidth={100}
            >
            <Tag
            style={{ marginBottom: '0px' }}
            size="mid"
            theme="warning"
            color="#fa9550"
            >
            相关调用栈{Index}(使用量为{stacknum[i]}):
            </Tag>
            {GetButtonList(funcName, [stacklist[i], stacknum[i]], filetype)}
            </Space>
            </>);
        Index = Index + 1;
      }
      result.push(ButtonList);
      result.push(ButtonList.length);
      result.push(sourcefunc);
      return result;
    }

    // 匹配可能的channel
    function getchannel(code) {
      let regex = /for range ([\w.]+)/;
      let match = code.match(regex);
      if (match) {
        return match[1];
      }
      regex = /([\w.]+) <-/;
      match = code.match(regex);
      if (match) {
        return match[1];
      }
      regex = /<- ([\w.]+)/;
      match = code.match(regex);
      if (match) {
        return match[1];
      }
      return '';
    }

    // 获取函数的源代码
    function getTableData(funcName, filetype) {
      const result = [];
      let allsample = 0;
      let asmCodeList = [];
      if (filetype === 1) {
        if (funcName in allGoFucntionDicList) {
          asmCodeList = allGoFucntionDicList[funcName].asmCodeArray;
        }
      } else {
        if (funcName in allFucntionDicList) {
          asmCodeList = allFucntionDicList[funcName].asmCodeArray;
        }
      }
      const data = [];
      const address = [];
      let channel = '';
      for (const item of asmCodeList) {
        const dataline = {};
        dataline.percent = item.percent;
        if (item.percent > 30) {
          dataline.percent = <Tag
          size="mid" theme="warning" color="#e37318" variant="light">{item.percent}</Tag>;
        }
        if (item.percent > 50) {
          dataline.percent = <Tag
          size="mid" theme="danger" color="#d54941" variant="light">{item.percent}</Tag>;
        }
        dataline.flet = item.number;
        dataline.cum = item.cum_number;
        if (item.cum_number !== '.') {
          if (filetype === 1) {
            channel = getchannel(item.code);
            allsample = allsample + parseInt(item.cum_number);
          } else {
            if (item.cum_number.endsWith('MB')) {
              allsample = allsample + parseFloat(item.cum_number.slice(0, -2)) * 1024;
            } else if (item.cum_number.endsWith('kB')) {
              allsample = allsample + parseFloat(item.cum_number.slice(0, -2));
            } else {
              allsample = allsample + parseFloat(item.cum_number.slice(0, -2)) * 1024 * 1024;
            }
          }
          address.push(item.addr);
        }
        dataline.code = <pre>{item.code}</pre>;
        data.push(dataline);
      }
      result.push(data); // 表格输出数据
      result.push(allsample); // 函数的总使用量
      result.push(address); // 问题代码行号
      result.push(channel); // 可能的阻塞channel名

      return result;
    }

    for (const item of Collapsedata) {
      if (item.funcName == 'None') {
        panels.push(<Panel
          header=
         {
          <Tag size="large" theme="success" variant="light">暂无建议</Tag>
         }
          key={item.id}
          >
          </Panel>);
        break;
      }
      const ButtonList = SetButtonList(item.funcName, item.filetype);
      let temp = [];
      temp = getRebusinessFuncInfo(item.funcName, item.filetype, item, ButtonList[2]);
      panels.push(<Panel
        header=
       {
        temp
       }
        key={item.id}
        >
        <div style={{ width: '98%' }}>
        <Card
        hoverShadow
        style={{
          padding: '5px',
        }}>
        { ButtonList[1] !== 0
          ? (ButtonList[0])
          : (<Tag
        style={{ marginBottom: '0px' }}
        size="mid"
        theme="warning"
        color="#fa9550"
        >
        无法获得调用栈，函数可能被内联
        </Tag>)
        }
        </Card>
        </div>
        </Panel>);
    }
    return panels;
  }
  // 获取调用图
  function getcallgraph(filetype) {
    console.log('getcallgraph', callgraphFile);
    return <Space direction="vertical"><Tag
    style={{ marginBottom: '10px' }}
    size="mid" theme="warning" color="#ffb98c" variant="light">
    {filetype === 0
      ? <>堆内存调用图（可拖动以及滚轮放大）</>
      : <>Goroutine调用图（可拖动以及滚轮放大）</>}
    </Tag>
    <Space direction="vertical">
    <div >
        <object width="1020px" height="600px" style={{ margin: '0  auto', display: 'block' }}
                id="object" data=
                { filetype === 0
                  ? callgraphFile
                  : goroutineCallgraphFile
                }
                type="image/svg+xml">CallGraph
                </object>
    </div>
    </Space>
    </Space>;
  }

  function gettopfuncs(filetype) {
    const listdata = [];
    for (let i = 0 ;i < toplist[filetype].length;i++) {
      listdata.push(<ListItem size="small" whiteSpace = 'pre' ><pre>{toplist[filetype][i]}</pre></ListItem>);
    }

    if (filetype === 0) {
      listdata.push(<>
        <strong style={{ fontSize: '12px' }}>flat: 表示在某个函数中直接申请的内存大小。</strong><br/>
        <strong style={{ fontSize: '12px' }}>flat%: 表示flat值占总内存申请的百分比。</strong><br/>
        <strong style={{ fontSize: '12px' }}>sum%: 表示从调用栈的顶部到当前函数的累积内存分配百分比。</strong><br/>
        <strong style={{ fontSize: '12px' }}>cum: 表示从调用栈的顶部到当前函数的累积内存分配。</strong><br/>
        <strong style={{ fontSize: '12px' }}>cum%: 表示cum值占总内存分配的百分比。</strong>
        </>);
    } else {
      listdata.push(<>
        <strong style={{ fontSize: '12px' }}>flat: 表示在某个函数中直接申请的goroutine数量。</strong><br/>
        <strong style={{ fontSize: '12px' }}>flat%: 表示flat值占总goroutine数量的百分比。</strong><br/>
        <strong style={{ fontSize: '12px' }}>sum%: 表示从调用栈的顶部到当前函数的累积goroutine分配百分比。</strong><br/>
        <strong style={{ fontSize: '12px' }}>cum: 表示从调用栈的顶部到当前函数的累积goroutine数量分配。</strong><br/>
        <strong style={{ fontSize: '12px' }}>cum%: 表示cum值占总goroutine数量分配的百分比。</strong>
        </>);
    }

    return listdata;
  }
  const [gongFengButtonVisible, setgongFengButtonVisible] = useState(false);
  const [suggestion, setsuggestion] = useState([]);// 建议列表
  const [url, seturl] = useState({});// 跳转URL列表
  const [updateSuggestion, setupdateSuggestion] = useState(0);

  function showsuggestion() { // 二次展示Fibona建议
    const suglist = [];
    for (let i = 0 ;i < suggestion.length; i = i + 1) {
      const temp = <><span style={{ color: 'red' }}><b>{suggestion[i].path}</b></span><br/><>{suggestion[i].commits}</><br/></>;
      suglist.push(temp);
      for (const key in url) {
        if (suggestion[i].path.includes(key)) {
          suglist.push(<><b>工蜂跳转链接：</b><Link href={url[key]}><u>{key}</u></Link><br/></>);
          break;
        }
      }
    }
    Log.debug('showsuggestion', suggestion, typeof(suggestion));
    return <Card style={{ padding: '5px', marginBottom: '10px' }}>
            <Comment
            avatar="https://fibona-sdk-1253358381.cos.ap-guangzhou.myqcloud.com/icon_imgs/fibona-logo.png"
            author="Fibona AI对比建议"
            content = {suglist}/>
      </Card>;
  }

  function AIcompare() {
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const args = qs.parse(location.search.slice(1));

    const onClickConfirm = () => {
      Log.debug('buttonRef handleClick', buttonRef);
      setVisibleConfirm(true);
    };
    // 监听CompareTaskList中的Fibona按钮是否被按下
    const handleClick = () => {
      setVisibleConfirm(false);
    };
    const cancelClick = () => {
      setVisibleConfirm(false);
    };

    const getresult = (error, data) => {
      if (error) {
        // 处理错误（没有报错时error为空）...
      } else {
        // 获取模型输出...
        Log.debug("fibonaresult", data, data.data[0]);
        const result = data.data[0].meta_data.content.split("|");
        const commits = [];
        for (let i = 15; i < result.length; i = i + 7) {
          const temp = {};
          if (!result[i].includes(':')) {
            continue;
          }
          temp.path = result[i];
          temp.commits = `分析说明：\n${result[i + 3]}\n修改意见：\n${result[i + 4]}\n`;
          commits.push(temp);
        }
        Log.debug('commits', commits, result);
        setsuggestion(commits);// 更新AI建议功能
        setupdateSuggestion(updateSuggestion + 1);// 更新数据库中的AI建议
        setgongFengButtonVisible(true);// 当模型有输出时才能够跳转到工蜂（TODO：存在业务代码时才跳转到工蜂）
      }
    };

    useEffect(() => {
      if (suggestion.length > 0 && updateSuggestion > 0) {
        Log.debug('savesuggestion', suggestion, args.taskID, JSON.stringify(suggestion));
        const requestParams = {
          task_id: args.taskID,
          suggestion: JSON.stringify(suggestion),
        };
        axios({
          method: 'post',
          url: `${HOST_URL}/api/v1/ai/suggestion`,
          params: requestParams,
        }).then((response) => {
          Log.debug('savesuggestion', response);
        });
      }
    }, [updateSuggestion]);

    const onProjectChange = (selectValue) => {
      setSelectProjectValue(selectValue);
    };
    const onBranchChange = (selectValue) => {
      setSelectBranchValue(selectValue);
    };
      const { Option } = Select;

    return <Popconfirm
    destroyOnClose = {false}
    theme={'default'} content={
      (<>
      <Card.Body
          style={{ padding: '5px', width: "760px"}}
          title="如已启动工蜂联动，试试选择项目提升AI建议效果吧"
        >
          <>项目：</>
        <Select
          value={selectProjectValue}
          onChange={onProjectChange}
          clearable
          filterable
          creatable
        >
        {Projects.map((option, index) => (
          <Option key={index} value={option.value} label={option.label}></Option>
        ))}
      </Select>
      <>分支：</>
      <Select
          value={selectBranchValue}
          onChange={onBranchChange}
          clearable
          filterable
          creatable
        >
        {Branches.map((option, index) => (
          <Option key={index} value={option.value} label={option.label}></Option>
        ))}
      </Select>
      </Card.Body>
      <CompareTaskList
      FibonaClick = {handleClick}
      tasks={ tasks }
        tableRow = {5}
        bordered = {true}
        selfTaskID = { args.taskID }
      domref = {buttonRef}
      callback = {getresult}
      uid = {getUserUid()}
      selectProjectValue = {selectProjectValue}
      selectBranchValue = {selectBranchValue}
      fileContext = {funcMap}
      ref={childRef}
      />
      </>
      )
    }
      popupProps={{
        placement: 'right',
        overlayStyle: { width: '870px', maxWidth: '870px', height: '500px', maxheight: '500px' },
      }}
      confirmBtn={
        <></>
      }
      cancelBtn={
        <Button theme={'danger'} size={'middle'} variant={'outline'}onClick={cancelClick }
        style={{ marginTop: '-40px', marginBottom: '0px' }}
        >
        关闭
      </Button>
      }
      visible={visibleConfirm}
      >
         <Button  ref={buttonRef} style={{ marginBottom: '10px' }}
        size="small"  theme="primary" icon={<SearchIcon />}
        onClick={onClickConfirm }
        className='AIcompare'
        >
        AI内存对比
        </Button>
        </Popconfirm>;
  }
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const handleClose1 = () => {
    setVisible1(false);
  };
  const handleClose2 = () => {
    setVisible2(false);
  };
  const handleClose3 = () => {
    setVisible3(false);
  };
  const handleClose4 = () => {
    setVisible4(false);
  };

  const handleChange = (current) => {
    setCurrent(current);
  };
  const gongfenghandleChange = (current) => {
    setgongfengstepcurrent(current);
  };
  const getGongFengInfo = (data) =>{
    setSelectProjectValue(data[0]);
    setSelectBranchValue(data[1]);
  };

  return <>
    <Tabs tabs={tabs} defaultActiveId={initActiveTab}>
      <TabPanel id="suggestions">
      {AIcompare()}
      <GongFengButton
        username = {username}
        uid = {getUserUid()}
        suggestion =  {suggestion}
        existSuggestion = {gongFengButtonVisible}
        taskid = {qs.parse(location.search.slice(1)).taskID}
        getInfo = {getGongFengInfo}
      ></GongFengButton>
        <div >
      <Collapse expandOnRowClick={false}>
      {
        renderPanels()
      }
      </Collapse>
        <Card style={{ padding: '5px' }}
        className='more-info'
        >
        <Space direction="vertical">
        <Space>
          <Tag
          style={{ marginBottom: '0px' }}
          size="mid"
          variant="light"
          color="#ffffff"
          >
          更多信息：
          </Tag>
        <div>
           <Button onClick={() => {
             setVisible1(true);
           }}
          size="small"  variant="text" theme="default"
          style={{ marginLeft: '10px' }}
          ><u>堆内存调用图</u></Button>
          <Dialog
            width="1100px"
            closeOnOverlayClick = {true}
            mode="model"
            closeBtn = {true}
            // confirmBtn = {null}
            footer = {false}
            draggable={true}
            visible={visible1}
            preventScrollThrough = {true}
            onClose={handleClose1}
            placement="center"
          >
            {getcallgraph(0)}
          </Dialog>
        </div>
        <div>
           <Button onClick={() => {
             setVisible2(true);
           }}
          size="small"  variant="text" theme="default"
          style={{ marginLeft: '10px' }}
          ><u>Goroutine调用图</u></Button>
          <Dialog
            width="1100px"
            closeOnOverlayClick = {true}
            mode="model"
            closeBtn = {true}
            // confirmBtn = {null}
            footer = {false}
            draggable={true}
            visible={visible2}
            preventScrollThrough = {true}
            onClose={handleClose2}
            placement="center"
          >
            {getcallgraph(1)}
          </Dialog>
        </div>
        <div>
           <Button onClick={() => {
             setVisible3(true);
           }}
          size="small"  variant="text" theme="default"
          style={{ marginLeft: '10px' }}
          ><u>内存热点函数</u></Button>
          <Dialog
            width="690px"
            closeOnOverlayClick = {true}
            mode="model"
            closeBtn = {true}
            // confirmBtn = {null}
            footer = {false}
            draggable={true}
            visible={visible3}
            preventScrollThrough = {true}
            onClose={handleClose3}
            placement="center"
          >
            <List size="small" stripe={true} split={false} style={{ width: '620px', height: '500px', overflow: 'auto' }}>
          {gettopfuncs(0)}
        </List>
          </Dialog>
        </div>
        <div>
           <Button onClick={() => {
             setVisible4(true);
           }}
          size="small"  variant="text" theme="default"
          style={{ marginLeft: '10px' }}
          ><u>Goroutine热点函数</u></Button>
          <Dialog
            width="690px"
            closeOnOverlayClick = {true}
            mode="model"
            closeBtn = {true}
            // confirmBtn = {null}
            footer = {false}
            draggable={true}
            visible={visible4}
            preventScrollThrough = {true}
            onClose={handleClose4}
            placement="center"
          >
            <List size="small" stripe={true} split={false} style={{ width: '620px', height: '500px', overflow: 'auto' }}>
          {gettopfuncs(1)}
        </List>
          </Dialog>
        </div>
          </Space>
          </Space>
          </Card>
           <Guide
            current={current}
            steps={steps}
            onChange={handleChange}
          />
        {gongFengButtonVisible === true ? <Guide
          current={gongfengstepcurrent}
          steps={gongfengsteps}
          onChange={gongfenghandleChange}
        /> : <></>}
        {showSuggestion === true ? showsuggestion() : <></>}
      </div>
      </TabPanel>
    </Tabs>
    </>;
};

GolangHeapResultCard.propTypes = {
  active_threads_json: PropTypes.string,
  callgraph_file: PropTypes.string,
  goroutine_callgraph_file: PropTypes.string,
  flamegraph_file: PropTypes.string,
  goroutine_flamegraph_file: PropTypes.string,
  extended_flamegraph_file: PropTypes.string,
  top_funcs: PropTypes.string,
  total_toplist: PropTypes.string,
  goroutine_top_funcs: PropTypes.string,
  analyse_suggestions: PropTypes.array,
  top_funcs_annotate_enable: PropTypes.bool,
  total_records: PropTypes.string,
  goroutine_total_records: PropTypes.string,
  display_flamegraph_search: PropTypes.bool,
  task_name: PropTypes.string,
  container_name: PropTypes.string,
  tid: PropTypes.string,
};
export default GolangHeapResultCard;
